





























































































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import User from '@/models/user'
import Account from '@/models/account'
import Investment from '@/models/investment'
import Company from '@/models/company'
import MemberAccessible from '@/models/member_accessible'
import Membership from '@/models/membership'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'

Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)
Vue.component('Checkbox', Checkbox)
Vue.component('Button', Button)

@Component
export default class UserVisibilityForm extends Vue {
  @Prop() user!: User
  @Prop() userStatus!: boolean
  @Prop({ default: () => { return true } }) enableEdit!: boolean

  member: Membership = new Membership()
  memberAccessibles: MemberAccessible[] = []
  accounts: Account[] = []
  investments: Investment[] = []
  companies: Company[] = []
  visibleAccounts: Account[] = []
  visibleInvestments: Investment[] = []
  visibleCompanies: Company[] = []
  selectedAccountIds: string[] = []
  selectedInvestmentIds: string[] = []
  selectedCompanyIds: string[] = []

  get isNew () {
    return !this.user.id
  }

  get visibleAccountsString () {
    return this.visibleAccounts.map(item => { return item.name }).join(', ') || '-'
  }

  get visibleInvestmentsString () {
    return this.visibleInvestments.map(item => { return item.name }).join(', ') || '-'
  }

  get visibleCompaniesString () {
    return this.visibleCompanies.map(item => { return item.name }).join(', ') || '-'
  }

  get investmentTypes () {
    return [...new Set(this.investments.map(item => {
      return item.investmentType
    }))].sort((a, b) => {
      return a > b ? 1 : 0
    })
  }

  @Watch('selectedAccountIds')
  selectedAccountIdsChanged () {
    this.selectionsChanged()
  }

  @Watch('selectedInvestmentIds')
  selectedInvestmentIdsChanged () {
    this.selectionsChanged()
  }

  @Watch('selectedCompanyIds')
  selectedCompanyIdsChanged () {
    this.selectionsChanged()
  }

  @Watch('userStatus')
  userStatusUpdated () {
    this.refreshVisibleItems()
  }

  async mounted () {
    await this.getMember()
    await this.getMemberAccessibles()
    this.getVisibleItems()

    this.getAccounts()
    this.getInvestments()
    this.getCompanies()
  }

  getVisibleItems () {
    this.getVisibleAccounts()
    this.getVisibleInvestments()
    this.getVisibleCompanies()
  }

  async refreshVisibleItems () {
    await this.getMemberAccessibles()
    this.getVisibleItems()
  }

  async getMember () {
    if (this.isNew) return

    this.member = (await Membership.where({
      userId: this.user.id,
      tenantId: this.user.currentTenantId
    }).all()).data[0]
  }

  async getMemberAccessibles () {
    if (this.isNew) return

    this.memberAccessibles = (await MemberAccessible.where({
      memberId: this.member.id
    }).per(100000).all()).data
  }

  async getVisibleAccounts () {
    if (this.isNew) return

    let accountIds = this.memberAccessibles.filter(item => {
      return item.accessibleType === 'Account'
    }).map(item => {
      return item.accessibleId
    })
    if (accountIds.length === 0) {
      accountIds = ['-1']
    }
    this.visibleAccounts = (await Account
      .where({ id: accountIds })
      .select(['name'])
      .per(100000)
      .all()
    ).data
    this.selectedAccountIds = this.visibleAccounts.map(item => { return item.id! })
  }

  async getVisibleInvestments () {
    if (this.isNew) return

    let investmentIds = this.memberAccessibles.filter(item => {
      return item.accessibleType === 'Investment'
    }).map(item => {
      return item.accessibleId
    })
    if (investmentIds.length === 0) {
      investmentIds = ['-1']
    }
    this.visibleInvestments = (await Investment
      .where({ id: investmentIds })
      .select(['name'])
      .per(100000)
      .all()
    ).data
    this.selectedInvestmentIds = this.visibleInvestments.map(item => { return item.id! })
  }

  async getVisibleCompanies () {
    if (this.isNew) return

    let companyIds = this.memberAccessibles.filter(item => {
      return item.accessibleType === 'Company'
    }).map(item => {
      return item.accessibleId
    })
    if (companyIds.length === 0) {
      companyIds = ['-1']
    }
    this.visibleCompanies = (await Company
      .where({ id: companyIds })
      .select(['name'])
      .per(100000)
      .all()
    ).data
    this.selectedCompanyIds = this.visibleCompanies.map(item => { return item.id! })
  }

  async getAccounts () {
    this.accounts = (await Account
      .select(['name'])
      .order('name')
      .per(100000)
      .all()
    ).data
  }

  async getInvestments () {
    this.investments = (await Investment
      .order('name')
      .select(['name', 'investmentType', 'active'])
      .per(100000)
      .all()
    ).data
  }

  async getCompanies () {
    this.companies = (await Company
      .select(['name'])
      .order('name')
      .per(100000)
      .all()
    ).data
  }

  @Emit()
  selectionsChanged () {
    const accessibleAccounts = this.selectedAccountIds.map(accountId => {
      return {
        memberId: this.member.id,
        accessibleType: 'Account',
        accessibleId: accountId
      }
    })
    const accessibleInvestments = this.selectedInvestmentIds.map(investmentId => {
      return {
        memberId: this.member.id,
        accessibleType: 'Investment',
        accessibleId: investmentId
      }
    })
    const accessibleCompanies = this.selectedCompanyIds.map(companyId => {
      return {
        memberId: this.member.id,
        accessibleType: 'Company',
        accessibleId: companyId
      }
    })
    return accessibleAccounts.concat(accessibleInvestments).concat(accessibleCompanies)
  }

  selectAllAccounts () {
    this.selectedAccountIds = this.accounts.map(item => { return item.id! })
  }

  unselectAllAccounts () {
    this.selectedAccountIds = []
  }

  selectAllInvestments () {
    this.selectedInvestmentIds = this.investments.map(item => { return item.id! })
  }

  selectAllInvestmentsOfType (type = null) {
    let investments = this.investments
    if (type) {
      investments = this.investments.filter(item => {
        return item.investmentType === type
      })
    }
    const newInvestmentIds = investments.map(item => { return item.id! })

    this.selectedInvestmentIds = this.selectedInvestmentIds.concat(
      newInvestmentIds.filter(item => {
        return !this.selectedInvestmentIds.includes(item)
      })
    )
  }

  unselectAllInvestments () {
    this.selectedInvestmentIds = []
  }

  selectAllCompanies () {
    this.selectedCompanyIds = this.companies.map(item => { return item.id! })
  }

  unselectAllCompanies () {
    this.selectedCompanyIds = []
  }
}
