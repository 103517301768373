






























































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Getter, Action, namespace } from 'vuex-class'

import User from '@/models/user'
import Investment from '@/models/investment'
import MemberAccessible from '@/models/member_accessible'
import MemberRole from '@/models/member_role'
import MemberPermission from '@/models/member_permission'
import Membership from '@/models/membership'

import Fieldset from 'primevue/fieldset'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import UserVisibilityForm from './UserVisibilityForm.vue'
import UserPermissionForm from './UserPermissionForm.vue'

Vue.component('Fieldset', Fieldset)
Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('UserVisibilityForm', UserVisibilityForm)
Vue.component('UserPermissionForm', UserPermissionForm)

const Auth = namespace('auth')

@Component
export default class UserForm extends Vue {
  @Prop() user!: User
  @Auth.Getter uid!: string
  @Auth.Getter currentTenantId!: string
  @Auth.Action('signout') dispatchSignout!: Function

  editedUser: User = new User()
  newAccessibles: MemberAccessible[] = []
  newRoles: MemberRole[] = []
  newPermissions: MemberPermission[] = []

  investments: Investment[] = []
  processing = false
  errors: string[] = []

  get isNew () {
    return !this.user.id
  }

  get invalid () {
    if (!this.editedUser.firstName) return true
    if (!this.editedUser.email) return true
    if (this.isNew && !this.editedUser.password) return true
    if (this.isNew && !this.editedUser.passwordConfirmation) return true

    const passwordsMatch = this.editedUser.password === this.editedUser.passwordConfirmation
    if (this.isNew && !passwordsMatch) return true

    return false
  }

  mounted () {
    this.duplicateOriginalUserForEditing()
  }

  onVisibilitySelectionsUpdated (memberAccessibles: MemberAccessible[]) {
    this.newAccessibles = memberAccessibles
  }

  onRolesUpdated (memberRoles: MemberRole[]) {
    this.newRoles = memberRoles
  }

  onPermissionsUpdated (memberPermissions: MemberPermission[]) {
    this.newPermissions = memberPermissions
  }

  prepareForSave () {
    this.processing = true

    if (this.isNew) {
      this.saveNew()
    } else {
      this.save()
    }
  }

  async saveNew () {
    Vue.prototype.$api.post('auth', {
      /* eslint-disable */
      email: this.editedUser.email,
      password: this.editedUser.password,
      password_confirmation: this.editedUser.passwordConfirmation,
      first_name: this.editedUser.firstName,
      last_name: this.editedUser.lastName
      /* eslint-enable */
    }).then(async (result: any) => {
      await this.saveComplete(result.data.data)
    }).catch((error: any) => {
      this.errors = error.response.data.errors.full_messages.join(',').split(',')
      this.processing = false
    })
  }

  async save () {
    const changedCurrentLoginEmail = this.uid === this.user.email &&
      this.editedUser.email !== this.user.email

    const userSaveSuccess = await this.editedUser.save()

    if (!userSaveSuccess) {
      this.processing = false
      this.errors = [String(this.editedUser.errors)]
      this.processing = false
      return
    }

    if (changedCurrentLoginEmail) {
      if (confirm('Login credentials successfully updated. You must sign back in.')) {
        this.signout()
      } else {
        this.signout()
      }
    }

    await this.saveComplete(this.editedUser)
  }

  duplicateOriginalUserForEditing () {
    this.editedUser = this.user ? new User(this.user) : new User()
    if (this.user.id !== undefined) {
      this.editedUser.isPersisted = true
    }
  }

  clearErrors () {
    this.errors = []
  }

  @Emit()
  cancel (user: User = this.user) {
    return user
  }

  @Emit()
  async saveComplete (user: User) {
    if (this.isNew) await this.createMembership(user)
    const member = await this.getMember()

    await this.saveMemberAccessibles(member)
    await this.saveMemberRoles(member)
    await this.saveMemberPermissions(member)

    return user
  }

  async saveMemberAccessibles (member: Membership) {
    // TO DO (FUTURE): make this a single bulk update instead
    //  of several network calls
    const existings = await this.getMemberAccessibles(member)
    this.destroyExistingMemberAccessibles(member, existings)
    this.addNewMemberAccessibles(member, existings)
  }

  async saveMemberRoles (member: Membership) {
    // TO DO (FUTURE): make this a single bulk update instead
    //  of several network calls
    const existings = await this.getMemberRoles(member)
    this.destroyExistingMemberRoles(member, existings)
    this.addNewMemberRoles(member, existings)
  }

  async saveMemberPermissions (member: Membership) {
    // TO DO (FUTURE): make this a single bulk update instead
    //  of several network calls
    const existings = await this.getMemberPermissions(member)
    this.destroyExistingMemberPermissions(member, existings)
    this.addNewMemberPermissions(member, existings)
  }

  async destroyExistingMemberAccessibles (member: Membership, existings: MemberAccessible[]) {
    for (let i = 0; i < existings.length; i++) {
      const existing = existings[i]
      if (!this.itemExistsInArray(existing, this.newAccessibles, ['accessibleType', 'accessibleId'])) {
        existing.destroy()
      }
    }
  }

  async addNewMemberAccessibles (member: Membership, existings: MemberAccessible[]) {
    for (let i = 0; i < this.newAccessibles.length; i++) {
      const newAccessible = this.newAccessibles[i]
      if (!this.itemExistsInArray(newAccessible, existings, ['accessibleType', 'accessibleId'])) {
        new MemberAccessible({
          memberId: member.id,
          accessibleType: newAccessible.accessibleType,
          accessibleId: newAccessible.accessibleId
        }).save()
      }
    }
  }

  async destroyExistingMemberRoles (member: Membership, existings: MemberRole[]) {
    for (let i = 0; i < existings.length; i++) {
      const existing = existings[i]
      if (!this.itemExistsInArray(existing, this.newRoles, ['roleId'])) {
        existing.destroy()
      }
    }
  }

  async addNewMemberRoles (member: Membership, existings: MemberRole[]) {
    for (let i = 0; i < this.newRoles.length; i++) {
      const newRole = this.newRoles[i]
      if (!this.itemExistsInArray(newRole, existings, ['roleId'])) {
        new MemberRole({
          memberId: member.id,
          roleId: newRole.roleId
        }).save()
      }
    }
  }

  async destroyExistingMemberPermissions (member: Membership, existings: MemberPermission[]) {
    for (let i = 0; i < existings.length; i++) {
      const existing = existings[i]
      if (!this.itemExistsInArray(existing, this.newPermissions, ['permissionId'])) {
        existing.destroy()
      }
    }
  }

  async addNewMemberPermissions (member: Membership, existings: MemberPermission[]) {
    for (let i = 0; i < this.newPermissions.length; i++) {
      const newPermission = this.newPermissions[i]
      if (!this.itemExistsInArray(newPermission, existings, ['permissionId'])) {
        new MemberPermission({
          memberId: member.id,
          permissionId: newPermission.permissionId
        }).save()
      }
    }
  }

  async createMembership (user: User) {
    await new Membership({
      userId: user.id,
      tenantId: this.currentTenantId
    }).save()
    this.user = (await User.find(user.id!)).data
  }

  async getMember () {
    return (await Membership.where({
      userId: this.user.id,
      tenantId: this.currentTenantId
    }).all()).data[0]
  }

  async getMemberAccessibles (member: Membership) {
    return (await MemberAccessible.where({
      memberId: member.id
    }).per(100000).all()).data
  }

  async getMemberRoles (member: Membership) {
    return (await MemberRole.where({
      memberId: member.id
    }).per(100000).all()).data
  }

  async getMemberPermissions (member: Membership) {
    return (await MemberPermission.where({
      memberId: member.id
    }).per(100000).all()).data
  }

  async signout () {
    try {
      await this.dispatchSignout()
      this.$router.push('/login')
    } catch (err) {
      // TODO: implement error dialog
    }
    this.$router.push('/login')
  }

  itemExistsInArray (item: any, array: any[], keys: string[]) {
    for (let i = 0; i < array.length; i++) {
      const arrayItem = array[i]

      let exists = true
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j]
        const valuesMatch = item[key] === arrayItem[key]
        if (!valuesMatch) exists = false
      }
      if (exists) return true
    }
    return false
  }
}
