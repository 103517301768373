



























































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import User from '@/models/user'

import Fieldset from 'primevue/fieldset'
import UserForm from './UserForm.vue'
import UserVisibilityForm from './UserVisibilityForm.vue'
import UserPermissionForm from './UserPermissionForm.vue'
import AppDialog from '@/components/AppDialog'

Vue.component('Fieldset', Fieldset)
Vue.component('UserForm', UserForm)
Vue.component('UserVisibilityForm', UserVisibilityForm)
Vue.component('UserPermissionForm', UserPermissionForm)
Vue.component('AppDialog', AppDialog)

const Auth = namespace('auth')

@Component
export default class UserContent extends Vue {
  @Prop() user!: User
  @Auth.Getter currentPermissions!: string

  activeStatus = this.user.active
  editMode = !this.user.id
  showInactiveConfirmationDialog = false

  enableUpdate = false

  mounted () {
    this.getCurrentPermissions()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableUpdate = permissions.includes('update_user')
  }

  enterEditMode () {
    this.editMode = true
  }

  exitEditMode () {
    this.editMode = false
  }

  showit = true
  async updateActiveStatus (value: boolean) {
    this.user.active = !!value
    await this.user.save()
    this.activeStatus = this.user.active
    this.showInactiveConfirmationDialog = false
  }

  @Emit()
  goBack () {
    return this.user
  }

  onSaveComplete () {
    this.goBack()
  }

  onCancelEdit () {
    this.goBack()
  }
}
