








































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import User from '@/models/user'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Toolbar from 'primevue/toolbar'
import UserContent from './components/UserContent'

Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('Toolbar', Toolbar)
Vue.component('UserContent', UserContent)

interface NSApiAuthResponse {
  data: {
    id: string;
    name: string;
  };
}

const Auth = namespace('auth')

@Component
export default class ManageUsers extends Vue {
  @Auth.Getter currentPermissions!: string

  loading = true
  users: User[] = []
  selectedUser: User | null = null

  enableCreate = false
  enableRead = false

  get filteredUsers () {
    return this.users.filter(item => {
      return item.firstName !== 'System'
    })
  }

  mounted () {
    this.getCurrentPermissions()
    this.getUsers()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreate = permissions.includes('create_user')
    this.enableRead = permissions.includes('read_user')
  }

  reset () {
    Vue.prototype.$api.get('api/v1/users/current')
      .then((resp: NSApiAuthResponse) => {
        const changedCurrentUserName = resp.data &&
          resp.data.id &&
          resp.data.name &&
          this.selectedUser &&
          this.selectedUser.id &&
          this.selectedUser.name &&
          String(this.selectedUser.id) === String(resp.data.id) &&
          this.selectedUser.name !== resp.data.name

        if (changedCurrentUserName) {
          // refresh page is current user name has been changed so the
          //  navigation bar updates
          const currentUser = resp.data.name
          localStorage.setItem('current-user', currentUser)
          location.reload()
        } else {
          this.selectedUser = null
          this.getUsers()
        }
      })
  }

  async getUsers () {
    if (!this.enableRead) return
    this.loading = true
    this.users = (await User.per(1000).order('firstName').order('lastName').all()).data
    this.loading = false
  }

  selectUser (user: User) {
    if (!user) return
    this.selectedUser = user
  }

  openNewUserForm () {
    this.selectedUser = new User()
  }
}
