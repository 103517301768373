
















































































import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import User from '@/models/user'
import Membership from '@/models/membership'
import Role from '@/models/role'
import Permission from '@/models/permission'
import RolePermission from '@/models/role_permission'
import MemberRole from '@/models/member_role'
import MemberPermission from '@/models/member_permission'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Panel from 'primevue/panel'
import Tooltip from 'primevue/tooltip'

Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)
Vue.component('Checkbox', Checkbox)
Vue.component('Button', Button)
Vue.component('Panel', Panel)
Vue.component('Tooltip', Tooltip)

@Component
export default class UserPermissionForm extends Vue {
  @Prop() user!: User
  @Prop({ default: () => { return true } }) enableEdit!: boolean

  member: Membership = new Membership()
  roles: Role[] = []
  permissions: Permission[] = []
  rolePermissions: RolePermission[] = []
  memberRoles: MemberRole[] = []
  memberPermissions: MemberPermission[] = []
  selectedRoleIds: string[] = []
  selectedPermissionNames: string[] = []

  permissibleObjects = [
    { name: 'Accounts', suffix: '_account' },
    { name: 'Transactions', suffix: '_account_transaction' },
    { name: 'Investments', suffix: '_investment' },
    { name: 'Contributions/Distributions', suffix: '_investment_transaction' },
    { name: 'Project Boards', suffix: '_company' },
    { name: 'Projects', suffix: '_project' },
    { name: 'Users', suffix: '_user' },
    { name: 'Audits', suffix: '_version' }
  ]

  permissibleActions = [
    { name: 'read', description: 'View' },
    { name: 'create', description: 'Create' },
    { name: 'update', description: 'Update' },
    { name: 'destroy', description: 'Delete' }
  ]

  @Watch('selectedRoleIds')
  selectedRoleIdsChanged () {
    this.rolesChanged()
  }

  @Watch('selectedPermissionNames')
  selectedPermissionNamesChanged () {
    this.permissionsChanged()
  }

  @Watch('derivedPermissions')
  onDerivedPermissionsChanged () {
    this.selectDerivedPermissions()
  }

  async mounted () {
    await this.getMember()
    await Promise.all([
      this.getRoles(),
      this.getPermissions(),
      this.getRolePermissions(),
      this.getMemberRoles(),
      this.getMemberPermissions()
    ])
    this.selectDerivedPermissions()
  }

  get isNew () {
    return !this.user.id
  }

  get derivedPermissions () {
    let derivedPermissions: string[] = []
    for (let i = 0; i < this.selectedRoleIds.length; i++) {
      const roleId = this.selectedRoleIds[i]
      const roles = this.roles.filter(item => { return String(item.id) === String(roleId) })
      let roleName = null
      if (roles && roles.length > 0) {
        roleName = roles[0].name
      }
      if (roleName === 'admin') {
        // all permissions are derived for the admin role
        const allPermissionNames = this.permissions.map(item => { return item.name })
        derivedPermissions = derivedPermissions.concat(allPermissionNames)
      } else {
        // get permissions assigned to role
        const rolePermissionIds = this.rolePermissions.filter(item => {
          return item.roleId === roleId
        }).map(item => {
          return item.permissionId
        })
        const permissionNames = this.permissions.filter(item => {
          return rolePermissionIds.includes(item.id!)
        }).map(item => {
          return item.name
        })
        derivedPermissions = derivedPermissions.concat(permissionNames)
      }
    }
    return [...new Set(derivedPermissions)]
  }

  async getMember () {
    if (this.isNew) return

    this.member = (await Membership.where({
      userId: this.user.id,
      tenantId: this.user.currentTenantId
    }).all()).data[0]
  }

  async getRoles () {
    this.roles = (await Role.per(1000).all()).data
  }

  async getPermissions () {
    this.permissions = (await Permission.per(1000).all()).data
  }

  async getRolePermissions () {
    this.rolePermissions = (await RolePermission.per(1000).all()).data
  }

  async getMemberRoles () {
    if (this.isNew) return

    this.memberRoles = (await MemberRole.where({
      memberId: this.member.id
    }).per(100000).all()).data

    this.selectedRoleIds = this.memberRoles.map(item => {
      return item.roleId
    })
  }

  async getMemberPermissions () {
    if (this.isNew) return

    this.memberPermissions = (await MemberPermission.where({
      memberId: this.member.id
    }).per(100000).all()).data

    const selectedPermissionIds = this.memberPermissions.map(item => {
      return item.permissionId
    })
    this.selectedPermissionNames = this.permissions.filter(item => {
      return selectedPermissionIds.includes(item.id!)
    }).map(item => {
      return item.name
    })
  }

  selectDerivedPermissions () {
    const permissionNames = this.selectedPermissionNames
    for (let i = 0; i < this.derivedPermissions.length; i++) {
      const derivedPermission = this.derivedPermissions[i]
      if (!permissionNames.includes(derivedPermission)) {
        permissionNames.push(derivedPermission)
      }
    }
    this.selectedPermissionNames = permissionNames
  }

  parentRolesForPermission (permissionName: string) {
    // returns the name of all selected roles that contain the given permission

    const parentRoles = []
    for (let i = 0; i < this.selectedRoleIds.length; i++) {
      const roleId = this.selectedRoleIds[i]
      const roles = this.roles.filter(item => { return String(item.id) === String(roleId) })
      let roleName = null
      if (roles && roles.length > 0) {
        roleName = roles[0].name
      }
      if (roleName === 'admin') {
        parentRoles.push(roleName)
      } else {
        const rolePermissionIds = this.rolePermissions.filter(item => {
          return item.roleId === roleId
        }).map(item => {
          return item.permissionId
        })
        const permissionNames = this.permissions.filter(item => {
          return rolePermissionIds.includes(item.id!)
        }).map(item => {
          return item.name
        })
        if (permissionNames.includes(permissionName)) {
          parentRoles.push(roleName)
        }
      }
    }
    return parentRoles
  }

  permissionExists (permissionName: string) {
    return this.permissions.filter(item => { return item.name === permissionName }).length > 0
  }

  selectAllPermissions () {
    this.selectedPermissionNames = this.permissions.map(item => { return item.name! })
  }

  unselectAllPermissions () {
    this.selectedPermissionNames = this.permissions.filter(item => {
      return this.derivedPermissions.includes(item.name)
    }).map(item => { return item.name! })
  }

  @Emit()
  rolesChanged () {
    this.permissionsChanged()
    return this.selectedRoleIds.map(roleId => {
      return {
        memberId: this.member.id,
        roleId: roleId
      }
    })
  }

  @Emit()
  permissionsChanged () {
    const selectedPermissions = this.permissions.filter(item => {
      return this.selectedPermissionNames.includes(item.name) &&
        !this.derivedPermissions.includes(item.name)
    })
    return selectedPermissions.map(permission => {
      return {
        memberId: this.member.id,
        permissionId: permission.id
      }
    })
  }
}
